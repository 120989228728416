<template>
  <v-container tag="section">
    <validation-observer v-slot="{ valid }">  
      <material-wizard
        title="Nova Importação de Dados"
        subtitle="Escolha o tipo de importação e faça o upload do arquivo a ser importado."
        v-model="tab"
        :available-steps="availableSteps"
        :tabs="tabs"
        class="mx-auto"
        @click:next="next(valid)"
        @click:prev="tab--"
        :loading="loading"
        :disabledBtnFinalizar="disabledBtnFinalizar"
      >
        <v-tab-item class="pb-1">
          <form @submit.prevent="next(valid)">
            <first-tab-content 
              @import:updated="handleTabContentUpdated"
            />
          </form>    
        </v-tab-item>    
        <v-tab-item class="pb-1">
          <second-tab-content 
            @encoding:selected="handleEncodingSelected"
            @file:uploaded="handleFileUploaded"
            @apagarBaseAtual:changed="handleApagarBaseAtualChanged"
            @apagarAprovacoesAtuais:changed="handleApagarAprovacoesAtuaisChanged"
            @selectedCategories:changed="handleSelectedCategoriesChanged"
            @selectedFields:changed="handleSelectedFieldsChanged"
            @selectedServicesCategories:changed="handleSelectedServicesCategoriesChanged"
            @selectedTiposServicos:changed="handleSelectedTiposServicosChanged"
            :dataImportType="importacao.tipoImportacao"
            :formattedFieldsCategories="formattedFieldsCategories"
            :servicosCategories="servicosCategories"
          />
        </v-tab-item>        
      </material-wizard>
    </validation-observer>
  </v-container>    
</template>

<script>

import ImportacoesDadosService from '@/services/ImportacoesDadosService';
import UsersService from '@/services/UsersService';
import tourMixins from '@/mixins/tourMixins';

export default {
  name: "ImportacaoDadosAdd",
  mixins: [tourMixins],
  components: {
    MaterialWizard:   () => import('@/components/base/MaterialWizard'),
    FirstTabContent:  () => import('@/components/administracao/importacoes-dados/tabs/FirstTabContent.vue'),
    SecondTabContent: () => import('@/components/administracao/importacoes-dados/tabs/SecondTabContent.vue'),
  },
  data: () => ({
    tab: 0,
    tabs: [
      'Informações básicas', 
      'Upload de arquivo'
    ],
    indicadoresFieldsCategories: {},
    selectedCategories: [],
    selectedFields: [],
    selectedServicesCategories: [],
    selectedTiposServicos: [],
    loading: false,
    importacao: {
      titulo: null,
      descricao: null,
      indgerVersion: null,
      data: null,
      mes: null,
      tipoImportacao: null,
      arquivo: null,
      encoding: 'UTF-8',
      apagarBaseAtual: false,
      apagarAprovacoesAtuais: false
    },
    arrTourPrimeiraTab: [
      {
        element: '#fs-titulo-importacao-dados',
        intro: 'Aqui você deverá inserir um título para sua importação.',
        scrollTo: "tooltip",
        position: 'bottom'
      },
      {
        element: '#fs-descricao-importacao-dados',
        intro: 'Aqui você poderá inserir uma descrição para sua importação.',
        scrollTo: "tooltip",
        position: 'bottom'
      },
      {
        element: '#fs-empresa-importacao-dados',
        intro: 'Aqui você deverá selecionar a empresa alvo que receberá a importação.',
        scrollTo: "tooltip",
        position: 'bottom'
      },
      {
        element: '#fs-tipo-importacao-dados',
        intro: 'Aqui você deverá informar o tipo de importação que será feita (AIS, Laudo, etc).',
        scrollTo: "tooltip",
        position: 'bottom'
      }
    ],
    arrTourSegundaTab: [
      {
        element: '#fs-upload-importacao-dados',
        intro: 'Nesta seção deverá ser enviado o arquivo com os dados a serem importados.',
        scrollTo: "tooltip",
        position: 'top'
      },
    ],
  }),
  mounted() {
    this.iniciarTourPrimeiraTab();
    this.getIndicadoresFieldsCategories();
  },
  computed: {
    availableSteps() {

      let steps = [0];
      
      if (this.tab1IsValid) {
        steps.push(0);
        steps.push(1);
      }
      
      if (this.tab2IsValid) {
        steps.push(2);
      }

      return steps;
    },
    tab1IsValid() {
      
      const { 
        titulo,
        tipoImportacao, 
        mes 
      } = this.importacao;

      return (
        titulo !== null &&
        tipoImportacao !== null &&
        tipoImportacao.id && 
        (
          !tipoImportacao.data_registro_obrigatorio || 
          mes !== null
        )
      );
    },
    tab2IsValid() {
      return this.tab1IsValid && 
             this.importacao.arquivo !== null &&
             this.selectedTiposServicos.length > 0;
    },
    disabledBtnFinalizar() {
      if (this.tab === 0) {
        return !this.tab1IsValid;
      }

      if (this.tab === 1) {
        return !this.tab2IsValid;
      }
    },
    formattedFieldsCategories() {
      if (!this.importacao?.tipoImportacao?.tipo) return [];

      const fieldsCategories = {
        "IndicadoresComerciaisV2": this.indicadoresFieldsCategories?.fields_indicadores_comerciais_v2_categories?.map(category => {
          return {
            slug: category.slug,
            category: category.category,
            fields: category.fields.map(f => f.field)
          }
        }),
        "IndicadoresServicosV2" : this.indicadoresFieldsCategories?.fields_indicadores_servicos_v2_categories?.map(category => {
          return {
            slug: category.slug,
            category: category.category,
            fields: category.fields.map(f => f.field)
          }
        }),
        "IndicadoresAlimentadores": this.indicadoresFieldsCategories?.fields_indicadores_alimentadores_categories?.map(category => {
          return {
            slug: category.slug,
            category: category.category,
            fields: category.fields.map(f => f.field)
          }
        }),
        "IndicadoresSubestacoes": this.indicadoresFieldsCategories?.fields_indicadores_subestacoes_categories?.map(category => {
          return {
            slug: category.slug,
            category: category.category,
            fields: category.fields.map(f => f.field)
          }
        }),
        "IndicadoresLD": this.indicadoresFieldsCategories?.fields_indicadores_linhas_distribuicao_categories?.map(category => {
          return {
            slug: category.slug,
            category: category.category,
            fields: category.fields.map(f => f.field)
          }
        }),
      };

      return fieldsCategories[this.importacao?.tipoImportacao?.tipo] ?? [];
    },
    servicosCategories() {
      if (!this.importacao?.tipoImportacao?.tipo) return [];

      return this.indicadoresFieldsCategories?.servicos_categories.map(category => {
        return {
          id: category.id,
          nome: category.nome,
          tipos_servicos: category.tipos_servicos.map(ts => ts.codigo)
        };
      });
    }
  },
  methods: {
    getIndicadoresFieldsCategories() {
      return UsersService.getIndicadoresFieldsCategories()
        .then(res => {
          this.indicadoresFieldsCategories = res;
        })
        .catch(err => {
          this.$toast.error('Erro ao recuperar temas dos indicadores.', '', {
            position: 'topRight'
          });
          throw err;
        });
    },
    async next(valid) {
      
      if (!valid) return;

      if (this.tab === 0 && !this.tab1IsValid) {
        return;
      }

      if (this.tab === 1) {
        
        if (!this.tab2IsValid) {
          return;
        }

        const sucesso = await this.cadastraNovaImportacao();
        if (!sucesso) {
          return;
        }

        this.$router.push({
          name: 'Importações de Dados'
        });

      } else {
        this.tab++; 
      }
    },
    handleTabContentUpdated(event) {
      this.importacao = Object.assign(this.importacao, event);
    },
    handleFileUploaded(file) {
      this.importacao.arquivo = file;
    },
    handleEncodingSelected(event) {
      this.importacao.encoding = event;
    },
    handleApagarBaseAtualChanged(event) {
      this.importacao.apagarBaseAtual = event;
    },
    handleApagarAprovacoesAtuaisChanged(event) {
      this.importacao.apagarAprovacoesAtuais = event;
    },
    handleSelectedCategoriesChanged(event) {
      this.selectedCategories = event;
    },
    handleSelectedFieldsChanged(event) {
      this.selectedFields = event;
    },
    handleSelectedServicesCategoriesChanged(event) {
      this.selectedServicesCategories = event;
    },
    handleSelectedTiposServicosChanged(event) {
      this.selectedTiposServicos = event;
    },
    async cadastraNovaImportacao() {

      let sucesso = false;
      this.loading = true;
      
      try {

        const {
          titulo,
          descricao,
          data,
          arquivo,
          encoding,
          tipoImportacao,
          indgerVersion,
          apagarBaseAtual,
          apagarAprovacoesAtuais
        } = this.importacao;

        const importacao =  {
          titulo,
          descricao,
          data_registro: data,
          arquivo_id: arquivo.id,
          encoding,
          tipo_importacao_id: tipoImportacao.id,
          indger_version: indgerVersion,
          apagar_base_atual: apagarBaseAtual,
          apagar_aprovacoes_atuais: indgerVersion === 0 ? apagarAprovacoesAtuais : false,
          campos_selecionados: this.selectedFields,
          temas_selecionados: this.getTemasSelecionadosByCampos(),
          tipos_servicos_selecionados: tipoImportacao.tipo === "IndicadoresServicosV2" ? this.selectedTiposServicos : [],
          categoria_servicos: tipoImportacao.tipo === "IndicadoresServicosV2" ? this.getCategoriasServicosByTiposServicos() : [],
        };
        sucesso = await ImportacoesDadosService.save(importacao);
        this.$toast.success('Importação cadastrada com sucesso.', '', { position: 'topRight' });

      } catch (e) {

        this.$toast.error('Ocorreu um erro ao cadastrar a importação.', '', { position: 'topRight' });
        console.log(e);

      } finally {
        this.loading = false;
      }

      return sucesso;
    },
    getTemasSelecionadosByCampos() {
      let temas = [];
      this.selectedFields.forEach(field => {
        let categoria = this.formattedFieldsCategories.find(cat => cat.fields.includes(field));

        if (categoria) {
          temas.push(categoria.slug);
        }
      });

      return Array.from(new Set(temas));
    },
    getCategoriasServicosByTiposServicos() {
      let categorias = [];
      this.selectedTiposServicos.forEach(ts => {
        let categoria = this.servicosCategories.find(cat => cat.tipos_servicos.includes(ts));

        if (categoria) {
          categorias.push(categoria.id);
        }
      });

      return Array.from(new Set(categorias));
    },
    iniciarTourPrimeiraTab() {

      this.doneLabel = 'Próximo';
      this.desativarTourAoSair = false;

      let onBeforeExitFunction = {
        func: (() => {
          this.tab = 1;
          this.initTourSegundaTab();
        })
      };
  
      this.iniciarTour(this.arrTourPrimeiraTab, [], [], [onBeforeExitFunction]);
    },
    initTourSegundaTab() {
      this.doneLabel = 'Finalizar';
      this.desativarTourAoSair = true;

      let onBeforeExitFunction = {
        func: (() => this.tab = 0)
      };

      this.iniciarTour(this.arrTourSegundaTab, [], [], [onBeforeExitFunction]);
    }
  }
}

</script>